import { render, staticRenderFns } from "./jishanleftright.vue?vue&type=template&id=4fde3143&scoped=true&"
import script from "./jishanleftright.vue?vue&type=script&lang=js&"
export * from "./jishanleftright.vue?vue&type=script&lang=js&"
import style0 from "./jishanleftright.vue?vue&type=style&index=0&id=4fde3143&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fde3143",
  null
  
)

export default component.exports